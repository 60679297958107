// jQuery to collapse the navbar on scroll
/*
$(window).scroll(function() {
    if ($(".navbar").offset().top > 50) {
        $(".navbar-fixed-top").addClass("top-nav-collapse");
    } else {
        $(".navbar-fixed-top").removeClass("top-nav-collapse");
    }
});
*/
$(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="_token"]').attr('content')
        }
    });
});

// jQuery for page scrolling feature - requires jQuery Easing plugin

var width = $( window ).width();
var offset = 0;

if (width < 950) offset = 0;
if (width < 768) offset = -130;

$(function() {
    $('a.page-scroll').bind('click', function(event) {
        var $anchor = $(this);
        $('html, body').stop().animate({
            scrollTop: $($anchor.attr('href')).offset().top+offset+2
        }, 1500, 'easeInOutExpo');
        event.preventDefault();
    });
});

// Closes the Responsive Menu on Menu Item Click
$('.navbar-collapse ul li a').click(function() {
    $('.navbar-toggle:visible').click();
});

$(window).load(function() {
  $('.flexslider').flexslider({
    animation: "fade",
    animationSpeed: 5000,
    controlNav: false,
    directionNav: false
  });
});

$( document ).ready(function() {
    $('#popup-zulassung').click(function(e) {
        e.preventDefault();
        $('.popup').fadeOut();
        $('.popup-zulassung').fadeIn();
    });

    $('#popup-spezialitaeten').click(function(e) {
        e.preventDefault();
        $('.popup').fadeOut();
        $('.popup-spezialitaeten').fadeIn();
    });

    $('.kontakt-link').click(function(e) {
        e.preventDefault();

        $('.popup').fadeOut();
        $( '#form-errors' ).html( '' );
        $('.popup-kontakt').fadeIn();

        $('html, body').stop().animate({
            scrollTop: $('.contact-form').offset().top-140+2
        }, 1500, 'easeInOutExpo');
    });

    $('.impressum-link').click(function(e) {
        e.preventDefault();
        $('.popup').fadeOut();
        $('.popup-impressum').fadeIn();

        $('html, body').stop().animate({
            scrollTop: $('.popup-impressum').offset().top+offset-10
        }, 1500, 'easeInOutExpo');
    });

    $('.popup-close a').click(function(e) {
        e.preventDefault();
        $(this).closest('.popup').fadeOut();
    });

    $('.btn-kontakt').click(function(e) {
        e.preventDefault();

        $.ajax({
            type: "POST",
            cache: false,
            url: '/kontakt',
            data: $('#contact-form').serialize(),
            dataType: 'json',
            success: function (data) {
                if (data.success === true) {
                    $( '#form-errors' ).hide();
                    $('.form-content').replaceWith( data.data );
                }

                if (data.success === false) {
                    var errors = data.response;
                    var errorsHtml;

                    errorsHtml = '';

                    $.each( errors , function( key, value ) {
                        errorsHtml += value[0] + '<br />';
                    });
                    errorsHtml += '';

                    $( '#form-errors' ).html( errorsHtml );
                }
            },
            error: function ( data ) {
                //process validation errors here.
                var errors = data.response; //this will get the errors response data.
                //show them somewhere in the markup
                //e.g
                errorsHtml = '<div class="alert alert-danger"><ul>';

                $.each( errors , function( key, value ) {
                    errorsHtml += '<li>' + value[0] + '</li>'; //showing only the first error.
                });
                errorsHtml += '</ul></di>';

                $( '#form-errors' ).html( errorsHtml ); //appending to a <div id="form-errors"></div> inside form
            }
        });

        return false;
    });
});